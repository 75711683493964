import React from "react";
import "./styles.css";
import visual from "./../../../assets/order_fuel_section_img1.png";
import bg from "./../../../assets/img_bg.png";
import brand1 from "./../../../assets/order_fuel_icon_1.png";
import brand2 from "./../../../assets/order_fuel_icon_2.png";
import typography from "./../../../styles/typography";
import colors from "./../../../styles/colors";

import brand3 from "./../../../assets/order_fuel_icon_3.png";
import HomeSection from "./../../../shared-components/HomeSection";

const Section2 = () => {
  const data = {
    mainTitle: "Order products anywhere, anytime",
    mainCaption:
      "With a state-of-the-art 32,000 sq ft. warehouse and partnerships with the UK’s top delivery providers, we guarantee reliable next-day delivery to keep your shelves stocked and customers satisfied. For peace of mind, we use the industry’s safest payment gateway to ensure secure transactions every time.",
    title1: "Saves time, money and manpower. ",
    title2: " ",
    title3: "",
    subTitle1: "",
    subTitle2: "",
    subTitle3: "",
  };
  return (
    <>
      <div className="title section2-title">
        <div
          className="fontsize62px lineheight62px"
          style={{
            ...typography.h136Px,
            ...{ textAlign: "center", fontSize: 24 },
          }}
        >
          Urbanshelf: Premier B2B Wholesale Platform for Convenience Retailers
        </div>
        <p
          className="section2-subtext fontsize18px"
          style={{  marginLeft: '2%', ...typography.p118Px, ...{ color: colors.grey1 } }}
        >
          Urbanshelf is your trusted B2B eCommerce platform, dedicated to
          serving convenience stores, off-licenses, mobile shops, and pound-plus
          stores throughout the UK. We offer an extensive range of premium
          products, including sweets, beverages, specialty accessories, and
          more, all at competitive wholesale prices tailored to help your
          business thrive.
        </p>
      </div>
      <div className="order-fuel-container">
        <HomeSection
          visual={visual}
          brand1={brand1}
          brand2={brand2}
          section={"OrderFuelSection"}
          brand3={brand3}
          data={data}
          showaftertext={true}
          class={"fuel-anywhere-section"}
          bg={bg}
        />
      </div>
    </>
  );
};

export default Section2;
