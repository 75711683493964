
const typography ={
  h148Px: {
    fontSize: 48,
    fontWeight: '800',
    fontStyle: 'normal',
    fontFamily: 'Inter',
    // letterSpacing: -0.025em
  },
  h130Px: {
    fontSize: 30,
    fontWeight: '800',
    fontStyle: 'normal',
    fontFamily: 'Inter',
    // letterSpacing: -0.025em
  },
  h136Px: {
    fontSize: 36,
    fontWeight: '800',
    fontStyle: 'normal',
    fontFamily: 'Inter',
  },
  h132: {
    fontFamily: 'Inter',
    fontSize: 32,
    fontWeight: '600',
    fontStyle: 'normal',
    // letterSpacing: -0.02,
  },
  h228Px: {
    fontFamily: 'Inter',
    fontSize: 28,
    fontWeight: '600',
    fontStyle: 'normal',
    // letterSpacing: -0.02,
  },
  h324Px: {
    fontFamily: 'Inter',
    fontSize: 24,
    fontWeight: '600',
    fontStyle: 'normal',
    // letterSpacing: -0.02,
  },
  h420Px: {
    fontFamily: 'Inter',
    fontSize: 20,
    fontWeight: '600',
    fontStyle: 'normal',
    // letterSpacing: -0.02,
  },
  h320Px: {
    fontFamily: 'Inter',
    fontSize: 20,
    fontStyle: 'normal',
    // letterSpacing: -0.02,
  },
  h424Px: {
    fontFamily: 'Inter',
    fontSize: 24,
    fontWeight: '500',
    fontStyle: 'normal',
    // letterSpacing: -0.02,
  },
  h516Px: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: '600',
    fontStyle: 'normal',
    // letterSpacing: -0.01,
  },
  p118Px: {
    fontFamily: 'Inter',
    fontSize: 18,
    fontStyle: 'normal',
  },
  p216Px: {
    fontSize: 16,
    fontStyle: 'normal',
  },
  p314Px: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontStyle: 'normal',
  },
  p412Px: {
    fontSize: 12,
    fontFamily: 'Inter',
    fontStyle: 'normal',
  },

  p118PxB: {
    fontWeight: '600',
    fontFamily: 'Inter',
    fontSize: 18,
    fontStyle: 'normal',
  },
  p118PxM: {
    fontWeight: '500',
    fontFamily: 'Inter',
    fontSize: 18,
    fontStyle: 'normal',
  },
  p118PxR: {
    fontWeight: 'normal',
    fontFamily: 'Inter',
    fontSize: 18,
    fontStyle: 'normal',
  },
  p118PxCaps: {
    fontWeight: '600',
    fontFamily: 'Inter',
    letterSpacing: 1,
    textTransform: 'uppercase',
    fontSize: 18,
    fontStyle: 'normal',
  },

  p216PxB: {
    fontSize: 16,
    fontStyle: 'normal',
    fontFamily: 'Inter',
    fontWeight: '600',
  },
  p216PxM: {
    fontSize: 16,
    fontStyle: 'normal',
    fontFamily: 'Inter',
    fontWeight: '500',
  },
  p216PxR: {
    fontSize: 16,
    fontStyle: 'normal',
    fontFamily: 'Inter',
    fontWeight: 'normal',
  },
  p216PxCaps: {
    fontSize: 16,
    fontStyle: 'normal',
    fontFamily: 'Inter',
    fontWeight: '600',
    letterSpacing: 1,
    textTransform: 'uppercase',
  },

  p314PxB: {
    fontSize: 14,
    fontStyle: 'normal',
    fontFamily: 'Inter',
    fontWeight: '600',
  },
  p314PxM: {
    fontSize: 14,
    fontStyle: 'normal',
    fontFamily: 'Inter',
    fontWeight: '500',
  },
  p314PxR: {
    fontSize: 14,
    fontStyle: 'normal',
    fontFamily: 'Inter',
    fontWeight: 'normal',
  },
  p314PxCaps: {
    fontSize: 14,
    fontStyle: 'normal',
    fontFamily: 'Inter',
    fontWeight: '600',
    // letterSpacing: 1,
    textTransform: 'uppercase',
  },

  p412PxB: {
    fontWeight: '600',
    fontFamily: 'Inter',
    fontSize: 12,
    fontStyle: 'normal',
  },
  p412PxM: {
    fontWeight: '500',
    fontFamily: 'Inter',
    fontSize: 12,
    fontStyle: 'normal',
  },
  p412PxR: {
    fontWeight: 'normal',
    fontFamily: 'Inter',
    fontSize: 12,
    fontStyle: 'normal',
  },
  p412PxCaps: {
    fontWeight: '600',
    fontFamily: 'Inter',
    // letterSpacing: 1,
    textTransform: 'uppercase',
    fontSize: 12,
    fontStyle: 'normal',
  },
};

export default typography;
