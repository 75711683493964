// import sclaing from './scaling';

const spacing = {
  spacingXxs: 4,
  spacing6: 6,
  spacingXs: 8,
  spacingS: 12,
  spacingM: 16,
  spacingL: 20,
  spacingXl: 24,
  spacingXxl: 28,
  spacingXxl2: 32,
  spacing18: 18,
  spacing14: 14,
  spacing25: 25,
  spacing40: 40,
  spacing10: 10,
  spacing44: 44,
  spacing50: 50,
  spacing5: 5,
  headerText: 115.5,
  spacing22: 22,
  spacing30: 30,
  spacing19: 19,
};

// Object.keys(spacing).forEach(space => {
//   spacing[space] = sclaing.scaleSize(spacing[space]);
// });

export default spacing;
