import React from "react";
import "./styles.css";
import typography from "./../../styles/typography";
import { ReactComponent as Facebook } from "./../../assets/facebook.svg";
import { ReactComponent as Twitter } from "./../../assets/twitter.svg";
import { ReactComponent as Insta } from "./../../assets/instagram.svg";
import { ReactComponent as Linkedin } from "./../../assets/linkedin.svg";
import { ReactComponent as Youtube } from "./../../assets/youtube.svg";
import { ReactComponent as Medium } from "./../../assets/medium.svg";
import { ReactComponent as Vimeo } from "./../../assets/vimeo.svg";
import { NavLink, useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  const footerData = [
    {
      name: "Contact",
      route: "/contactus",
    },
    {
      name: "Terms & Conditions",
      route: "/terms",
    },
    {
      name: "Privacy Policy",
      route: "/policy",
    },
  ];

  return (
    <div
      className="footer"
      style={{
        ...(location?.pathname === "/blogs"
          ? { background: "#fafafa" }
          : { background: "#fff" }),
      }}
    >
      <div className="padding">
        <div className="footer-text" style={{ marginBottom: "2.35vw" }}>
          {footerData.map((footer, index) => {
            return (
              <NavLink
                key={index}
                to={footer.route}
                activeStyle={{ color: "#ca2128" }}
                style={{ ...typography.p314PxM, cursor: "pointer" }}
                className="nav-link fontsize14px"
              >
                {footer.name}
              </NavLink>
            );

          })}
        </div>

        <div
          style={{
            marginBottom: "2.35vw",
            textAlign: "center",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
        </div>
        <div
          className="fontsize14px"
          style={{
            ...typography.p314PxR,
            ...{ textAlign: "center", color: "#ca2128" },
          }}
        >
          {"© Urbanshelf.".toUpperCase()}
        </div>
      </div>
    </div>
  );
};

export default Footer;
