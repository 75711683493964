import React from "react";
import typography from "../../styles/typography";
import "./styles.css";
import Tilt from "react-parallax-tilt";

const HomeSection = (props) => {
  return (
    <div className={props.class ? `home-root ${props.class}` : "home-root"}>
      {!props.showaftertext && (
        <div
          className={
            props.image_class
              ? `home-image-right ${props.image_class}`
              : "home-image-right"
          }
        >
          <Tilt
            className="parallax-effect"
            perspective={500}
            style={{ position: "relative" }}
          >
            <img src={props.mapImg} alt="" width="100%" className="map-img" />
            <img
              src={props.visual}
              alt=""
              width="100%"
              className="homeSection-image"
            />
            <div
              style={{ position: "absolute", top: "57.5%", right: "26.5%" }}
              className="circle-ripple"
            ></div>
          </Tilt>
        </div>
      )}
      <div
        className={
          props.content_class
            ? `home-direction ${props.content_class}`
            : "home-direction"
        }
      >
        <p
          className={
            props.section === "order-tracking"
              ? "section-caption fontsize18px lineheight26px color-black"
              : "section-caption lineheight26px fontsize18px"
          }
        >
          {props?.data?.mainCaption}{" "}
        </p>

        <div className="direction-row padding32">
          <img
            className="image marginRight16px"
            src={props.brand1}
            alt=""
          ></img>
          <div>
            <p style={{ margin: 0 }}>
              <span
                className={
                  props.section === "order-tracking"
                    ? "main-title fontsize18px color-black"
                    : "main-title fontsize18px"
                }
              >
                {props?.data?.title1}
                <span
                  className={
                    props.section === "order-tracking"
                      ? "main-caption fontsize18px color-grey"
                      : "main-caption fontsize18px"
                  }
                >
                  {props?.data?.subTitle1}
                </span>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSection;
