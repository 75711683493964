import React from 'react';
import { Parser } from 'html-to-react'

const PrivacyPolicy = () => {
    const policy = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Privacy Policy - Urbanshelf</title>
</head>
<body>
    <h1>Privacy Policy</h1>
    <p>
        Urbanshelf has created this Privacy Notice in order to demonstrate our strong commitment to protecting the 
        privacy of all visitors to our site. If you have any questions or comments about this Privacy Notice, they 
        can be directed to <a href="mailto:techfogbusiness@gmail.com">techfogbusiness@gmail.com</a> or in writing to the 
        address supplied at the bottom of this page.
    </p>

    <h2>Part One: All visitors to www.urbanshelf.co.uk are promised that:</h2>
    <ul>
        <li>Urbanshelf will always respect your right to privacy. However, we understand that the internet is not a secure medium and therefore we have implemented security measures to, where possible, protect the loss, misuse or alteration of information placed under our control.</li>
        <li>All data gathered as a result of your website visit or product/service purchase or visit to our business will be treated as confidential.</li>
        <li>All orders or bookings placed online will be confirmed by email if you have provided an email address.</li>
    </ul>

    <h2>Part Two: How we use your data:</h2>
    <ul>
        <li>To place an order, make a booking or enter a promotion, you will be required to supply contact and personal information.</li>
        <li>For our ‘customers’, we use your personal data for certain legitimate business purposes, for example, we may ask for your name, address, phone number and email, to tell you about new products and services and to send you marketing communication and offers to use in stores or on our website. We may also send you surveys to help us improve how we service you.</li>
        <li>We will consider you as a ‘customer’ for 13 months since the date of your last order/booking. If you do not order/book within 13 months you will automatically be opted out of receiving our direct marketing.</li>
        <li>If you order again from us after 13 months and have not opted out, we have a legitimate interest to re-commence sending you marketing communications about our products and services.</li>
        <li>You can opt out of these messages at any time by contacting our team at <a href="mailto:techfogbusiness@gmail.com">techfogbusiness@gmail.com</a>.</li>
        <li>We may use personal and profile data to tailor your experience on our interactive services.</li>
        <li>We may use your IP address or other identifying information to help diagnose problems with our server, to monitor numbers of users and/or to administer our interactive services as well as to identify and eliminate fraud.</li>
        <li>Urbanshelf would encourage you to be cautious about the information you choose to release and to safeguard passwords.</li>
        <li>For people who have registered on our website but haven't yet bought any of our products and services, we will ask for your consent to process your data before we send you marketing offers.</li>
        <li>We do not transfer your personal information outside the European Economic Area.</li>
        <li>We will request that users who wish to submit personal information are aged 16 years or older or that minors obtain permission from their parents or legal guardians before submitting such information.</li>
        <li>Whenever we process your personal data for these purposes, we will ensure that we always keep your personal data rights in high regard and take account of these rights. You have the right to object to this processing if you wish, and if you wish to do so please contact us at <a href="mailto:techfogbusiness@gmail.com">techfogbusiness@gmail.com</a>. Please bear in mind that if you object this may affect our ability to carry out the tasks above for your benefit.</li>
    </ul>

    <h2>Part Three: How long will you keep my data?</h2>
    <p>
        We'll normally hold onto your data if you're a customer, for as long as is necessary to give you the services you're using in accordance with our retention policy.
    </p>

    <h2>Part Four: Your rights as a data subject</h2>
    <p>At any point while we are in possession of or processing your personal data, you, the data subject, have the following rights:</p>
    <ul>
        <li>Right of access – you have the right to request a copy of the information that we hold about you.</li>
        <li>Right of rectification – you have a right to correct data that we hold about you that is inaccurate or incomplete.</li>
        <li>Right to be forgotten – in certain circumstances, you can ask for the data we hold about you to be erased from our records.</li>
        <li>Right to restriction of processing – where certain conditions apply to have a right to restrict the processing.</li>
        <li>Right of portability – you have the right to have the data we hold about you transferred to another organisation.</li>
        <li>Right to object – you have the right to object to certain types of processing such as direct marketing.</li>
        <li>Right to object to automated processing, including profiling – you also have the right to be subject to the legal effects of automated processing or profiling.</li>
    </ul>
    <p>
        To get in touch, email: <a href="mailto:techfogbusiness@gmail.com">techfogbusiness@gmail.com</a><br>
    </p>

    <h2>Part Five: Cookies</h2>
    <p>
        Currently, cookies are used on <a href="mailto:techfogbusiness@gmail.com">techfogbusiness@gmail.com</a> to identify you for usability purposes. These in no way track your movement around the service or record any information about you. They are used specifically for identifying if you have used the site before & remarketing purposes.
    </p>
    <p>
        For further information on cookies please refer to 
        <a href="http://www.youronlinechoices.com/uk/your-ad-choices" target="_blank">http://www.youronlinechoices.com/uk/your-ad-choices</a>.
    </p>

    <h2>Part Six: Disclaimer</h2>
    <p>
        If Urbanshelf provides links to other services, Urbanshelf is not responsible for the content or privacy practices of these services. Urbanshelf reserves the right to modify, alter or otherwise update this Privacy notice at any time, and you are therefore encouraged to review it from time to time.
    </p>

    <h2>Part Seven: Trademarks and Copyright</h2>
    <p>
        All content included on this site, such as text, graphics, button icons, images and software, is the property of Urbanshelf and is protected by UK and European copyright laws. The content and software on this site may be used as either or both of an informational or shopping resource. Any other use, including the reproduction, modification, distribution, transmission, republication, display or performance, of the content on this site is strictly prohibited. Other Urbanshelf graphics, logos and trade names are trademarks of Urbanshelf. Urbanshelf trademarks may not be used in connection with any product or service that is not affiliated with Urbanshelf in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits Urbanshelf.
    </p>

    <h2>Part Eight: Terms and Conditions</h2>
    <p>
        Our company terms & conditions can be viewed here from the link below.
    </p>

    <h2>Part Nine: Contact Us</h2>
    <p>
        If you have any questions about this Privacy Notice or you wish to make a complaint about how your personal data is being processed by us, or how your complaint has been handled, you have the right to lodge a complaint directly with the Supervisory Authority and Urbanshelf data protection representative.
    </p>
    <p>
        The details for the latter are:
    </p>
    <p>
        Data Protection: <a href="mailto:techfogbusiness@gmail.com">techfogbusiness@gmail.com</a><br>
        Urbanshelf, 31 the borough, Farnham, England, GU 97NJ - Registered in England
    </p>
</body>
</html>`
    return (
        <div style={{marginRight:100,marginLeft:100}}>
        {Parser().parse(policy)}
    </div>
    )
}

export default PrivacyPolicy;
