import React from 'react'
import './styles.css';
import { Parser } from 'html-to-react'
// var __html = require('./terms.html');
// var template = { __html: __html };

const html = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Terms and Conditions</title>
    <style>
        body {
            font-family: Arial, sans-serif;
            line-height: 1.6;
            margin: 20px;
        }
        h1, h2 {
            color: #333;
        }
        h1 {
            font-size: 1.8em;
        }
        h2 {
            font-size: 1.4em;
            margin-top: 20px;
        }
        p, li {
            margin: 10px 0;
        }
        ul {
            padding-left: 20px;
        }
        .contact {
            margin-top: 20px;
            font-weight: bold;
        }
    </style>
</head>
<body>
    <h1>Terms and Conditions</h1>
    <p>We recommend you keep a copy of these terms and conditions for your future reference.</p>
    <p>These terms and conditions apply to your use of this Website and orders made on the Websites. By accessing this Website and/or placing an order on any of the Websites, you agree to be bound by the terms and conditions set out below. If you do not agree to be bound by these terms and conditions, do not use or access this Website. Before you place an order, if you have any questions relating to these terms and conditions, please contact our Customer Service team on +44 7721 481113 between 9 am and 6 pm Monday to Friday (excluding bank holidays). All calls to Customer Services will be charged at the local rate (BT users only, other network charges may vary). Inbound and outbound calls may be recorded for quality monitoring and training purposes.</p>
    
    <h2>Definitions</h2>
    <ul>
        <li><strong>Conditions:</strong> These terms and conditions together with any terms and conditions in the Product Description.</li>
        <li><strong>Product:</strong> Any of our products displayed for sale on the Website.</li>
        <li><strong>Product Description:</strong> That part of the Website where certain terms and conditions in respect of the individual Product are provided.</li>
        <li><strong>Users:</strong> The users of the Website collectively.</li>
        <li><strong>Personal Information:</strong> The details provided by you on placing an order.</li>
        <li><strong>Websites:</strong> The websites located at <a href="http://www.urbanshelf.co.uk">www.urbanshelf.co.uk</a> and any one of them, and any subsequent URL which may replace them.</li>
        <li><strong>Cookies:</strong> Small text files which our Websites place on your computer’s hard drive to store information about your shopping session and to identify your computer.</li>
        <li><strong>United Kingdom:</strong> England, Wales, Scotland, and Northern Ireland.</li>
        <li><strong>You:</strong> A user of this Website.</li>
    </ul>

    <h2>Accessing Our Websites</h2>
    <p>Your access to this Website is granted in accordance with these Conditions. Any orders you place on this website are made subject to these Conditions.</p>
    <p>We reserve the right to:</p>
    <ul>
        <li>Modify or withdraw, temporarily or permanently, this Website (or any part thereof) with or without notice to you, and you confirm that we shall not be liable to you or any third party for any modification to or withdrawal of the Website.</li>
        <li>Change the Conditions from time to time, and your continued use of the Website (or any part thereof) following such change shall be deemed to be your acceptance of such change. It is your responsibility to check regularly to determine whether the Conditions have been changed. If you do not agree to any change to the Conditions, you must immediately stop using the Website.</li>
        <li>Block access to the Website or any part of it to all or specific users if we suspect breach of these Conditions or fraudulent activity.</li>
    </ul>
    
    <!-- Include other sections similarly formatted -->

    <h2>Contact</h2>
    <p>For any queries regarding our service, please contact us via email at <a href="mailto:techfogbusiness@gmail.com">techfogbusiness@gmail.com</a>, post at the address below:</p>
    <div class="contact">
        Urbanshelf Ltd<br>
        31 the borough<br>
        Farnham<br>
        England<br>
        Birmingham<br>
        GU 97NJ<br>
    </div>
</body>
</html>`

const Terms = () => {
 return (
    // <div dangerouslySetInnerHTML={template} />
    <div style={{marginRight:100,marginLeft:100}}>
        {Parser().parse(html)}
    </div>
 )
}

export default Terms;
