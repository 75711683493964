import React from 'react'
import typography from '../../../styles/typography';
import ExpandView from '../../../shared-components/ExpandView'
import './styles.css'

const FAQSection = () => {
    return (
        <>
        <div className="qtns-container" style={{justifyContent:"center",alignItems:"center",flexDirection:"column",display:"flex"}}>
            <div className="section6-margin fontsize36px" style={{...typography.h136Px,...{paddingBottom:'3%'}}}>
            Frequently asked questions
            </div>
            <ExpandView data={[
                {
                    'Q': 'How safe it is to order products at your doorstep?',
                    'A': 'We deliver order using shipping vans equipped with IoT and tamper-proof electronic locks protected by cutting-edge geo - fencing technology.'
                },
                {
                    'Q': 'Is doorstep delivery expensive?',
                    'A': 'We do charge a nominal convenience fee for delivering it to your doorstep at any time and location.'
                },
                {
                    'Q': 'How can I pay for my order?',
                    'A': 'We accept payment through Debit card.'
                }
            ]}/>

        </div>
        </>
    )
}

export default FAQSection;
