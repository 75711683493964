const colors = {
  black: '#191a1f',
  green: '#21bf73',
  red: '#ff512b',
  white: '#ffffff',
  purple: '#6d75b6',
  peach: '#fde9eb',
  green10: '#e9f9f1',
  black60: '#6b6c6f',
  black30: '#bababc',
  black10: '#e8e8e9',
  black20: '#d1d1d2',
  green50: '#90dfb9',
  black5: '#f3f4f4',
  yellow: '#f89f36',
  black40: '#a3a3a5',
  veryLightPurple: '#f9fafe',
  veryLightPink: '#fef5eb',
  black30Two: 'rgba(25,26,31,0.3)',
  darkSkyBlue: '#4d80e4',
  blackTwo40: 'rgba(25, 26, 31, 0.4)',
  blackTwo30: 'rgba(25, 26, 31, 0.3)',
  blackTwo60: 'rgba(25, 26, 31, 0.6)',
  veryLightPink2: '#ffe4e4',
  coral: '#ff4949',
  transparent: 'transparent',
  urbanshelfRed: '#CA2128',
  grey3: '#E2E2E2',
  grey4: '#EAEAEA',
  black1: '#111111',
  black2: '#444444',
  grey1: '#777777',
  grey2: '#B0B0B0',
  urbanshelfRed_d: '#771A23',
  urbanshelfRed_l: '#FBE9EA',
  danger: '#FF6761',
  warning: '#F8921C',
  success: '#87BF11',
  divider: '#F4F4F4',
  appColor: '#4F6D7A',
  stepColor: '#aaaaaa',
  notificationToggle: '#19C29A',
};

export default colors;
